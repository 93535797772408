<template>
  <component :is="ComponentToRender" :="data" />
</template>

<script lang="ts" setup>
import { pick } from 'radash'
import { joinURL } from 'ufo'
import type { UnwrapRef } from 'vue'
import * as logger from '~/utils/logging'
import type { ContentPageInterface } from '~/types'

const toI18nCompatableSlug = (path: string) => {
  return path.replace(/^\/?(es|en)\//, '')
}

const useDynamicComponent = <
  T extends {
    type: string
    collection: string
    uuid: string
  },
>(
  data: MaybeRef<T | undefined>,
) => {
  const getComponentByType = (type: string) => {
    switch (type) {
      case 'Article':
        return resolveComponent('LazyDynamicArticle')
      case 'Collection':
        return resolveComponent('LazyDynamicCollection')
      case 'DailyMessage':
        return resolveComponent('LazyDynamicDailyMessage')
      case 'Event':
        return resolveComponent('LazyDynamicEvent')
      case 'Static':
        return resolveComponent('LazyDynamicStatic')
      case 'Stub':
        return resolveComponent('LazyDynamicStub')
      case 'Webform':
        return resolveComponent('LazyDynamicWebform')
      case 'BadgeTerm':
      case 'IdentityTerm':
      case 'TagTerm':
      case 'TopicTerm':
        return resolveComponent('LazyDynamicTerm')
      case 'PersonAuthor':
      case 'PersonLeadership':
      case 'PersonSpeaker':
        return resolveComponent('LazyDynamicPerson')
    }

    return false
  }

  const getComponentByCollection = (collection: string) => {
    switch (collection) {
      case 'daily-word-magazine':
        return resolveComponent('LazyDynamicDailyWordMagazine')
      case 'spirituality-and-health':
      case 'spirituality-health-magazine':
        return resolveComponent('LazyDynamicSpiritualityAndHealthMagazine')
      case 'unity-magazine':
        return resolveComponent('LazyDynamicMagazine')
    }
    return false
  }

  const getComponentByUuid = (uuid: string) => {
    switch (uuid) {
      case '437ddb5d-b6cc-4bdf-bb8e-e40da37ab8e0':
        return resolveComponent('LazyDynamicWebformContactUs')
      case '0e585fcf-fc10-4e2e-a5cb-ed452a497775':
        return resolveComponent('LazyDynamicWebformBibleInterpretationRequest')
      case '245b684f-3834-413e-9bc0-751afb2c694c':
        return resolveComponent('LazyDynamicWebformMessageOfHopeInterest')
      case '8ce299d3-eaf7-40ae-923d-d909c127c1bd':
        return resolveComponent('LazyDynamicWebformMyBurningBowlReleaseForm')
      case 'efccc335-57df-42ef-a075-d1f57b402bf3':
        return resolveComponent('LazyDynamicWebformOutreachProgramMaterials')
      case '3ddfa2ed-1585-4667-bc8a-029c2878c6ab':
        return resolveComponent('LazyDynamicWebformMyPrayerStory')
      case '27238ce0-523c-426b-a75d-174c69360ad7':
        return resolveComponent('LazyDynamicWebformRequestPrayer')
      case 'bf9e5f42-c197-4326-b50c-fbdc38733057':
        return resolveComponent('LazyDynamicWebformWdoPrayer')
      case '6b266116-9011-419b-9903-53971e29aa96':
        return resolveComponent('LazyDynamicCustomDailyWordCalendar')
      case '59ebfb26-78d7-4a8a-bb9f-c9da45f89589':
        return resolveComponent('LazyDynamicCustomRetreatsAndEvents')
      case 'a2571fcb-0f0b-4d5d-9a46-e949748a3785':
        return resolveComponent('LazyDynamicCustomUnityLeaders')
      case 'fe7e9ca7-6f8f-490c-9eda-d73b9f4b1524':
        return resolveComponent('LazyDynamicWebformMyWordOfIntention')
      case '4a2dca73-fae2-4b42-8c66-4f3dd4fa9dc9':
        return resolveComponent('LazyDynamicWebformWholesaleCreditApplication')
      case 'cbf07139-5180-4999-be19-c1b0b80c2d5b':
        return resolveComponent('LazyDynamicWebformQuestionUnityReimagined')
    }
    return false
  }

  const getDynamicComponent = (data: T | undefined) => {
    if (!data) return resolveComponent('LazyDynamicStub')

    const { type, collection, uuid } = data
    return (
      getComponentByUuid(uuid) ||
      getComponentByCollection(collection) ||
      getComponentByType(type) ||
      resolveComponent('LazyDynamicPage')
    )
  }

  return computed(() => getDynamicComponent(unref(data)))
}

const { locale, setLocale } = useI18n()

const useI18nParams = (page: typeof data) => {
  const setI18nParams = useSetI18nParams()
  const _page = unref(page)

  const makeParams = (_data: any): Record<string, { slug: string }> => {
    const params: any = {}

    ;['en', 'es'].forEach((lang) => {
      params[lang] = _data?.[lang]?.path
        ? {
            slug: toI18nCompatableSlug(withoutHost(_data[lang].path)),
          }
        : false
    })

    return params
  }

  const i18nParams = makeParams(_page)
  setI18nParams(i18nParams)

  // remove head links that do not exist
  const missing = Object.entries(i18nParams)
    .filter(([, params]) => {
      return !params
    })
    .map(([lang]) => lang)
    .map((lang) => [lang, `${lang}-US`])
    .flat()

  if (missing.length) {
    console.log('Removing SEO links for non-translated locales:', missing)
    useHead({
      link: missing.map((code) => ({ id: `i18n-alt-${code}` })),
      meta: missing.map((code) => ({ id: `i18n-og-alt-${code}` })),
    })
  }

  if (!i18nParams[unref(locale)]) {
    const data = _page && pick(_page, ['uuid', 'type', 'title'])

    logger.error('[useI18nParams] locale not found.', {
      locale: unref(locale),
      i18nParams,
      data,
    })

    if (import.meta.client) return

    throw createError({
      statusCode: 404,
      statusMessage: 'Page Not Found',
      cause: 'translation-not-found',
    })
  }
}

const route = useRoute()
const { path, query } = useRouter().currentRoute.value

const slug = computed(() => {
  const slug = route.params.slug
  if (!slug) return []
  if (typeof slug === 'string') return [slug]
  return slug
})

const apiPath = joinURL('/api/data/route', ...unref(slug))
const { data, error, status } = await useApiFetch<ContentPageInterface>(
  apiPath,
  {
    query: pick(query, ['page']),
    watch: [slug],
  },
)

if (unref(error)) {
  logger.error(`[slug] error fetching path data on "${path}"`, {
    query,
    slug: unref(slug),
    error: unref(error),
  })
}

if (!unref(data)) {
  logger.warn(`[slug] empty data response on "${path}"`, {
    lang: unref(locale),
    error: unref(error),
    status: unref(status),
  })

  if (import.meta.server) {
    throw createError({
      statusCode: 404,
      statusMessage: 'Page Not Found',
      cause: 'page-not-found',
      data: unref(data),
      fatal: true,
    })
  }
}

type PageDataType = Exclude<UnwrapRef<typeof data>, undefined>

const ComponentToRender = useDynamicComponent<PageDataType>(data)

useI18nParams(data)
// @todo: convert paths to absolute urls
useHead({
  title: unref(data)?.title,
  htmlAttrs: {
    lang: unref(locale),
  },
})

const metatags = computed(() => unref(data)?.metatags)
useContentHeadMetatags(metatags)

// set locale based on page data language
watch(data, () => {
  const _locale = unref(locale)
  const { locale: langcode } = unref(data) || {}
  if (!langcode) return
  if (langcode === _locale) return
  setLocale(langcode)
})

definePageMeta({
  layout: 'unity',
  // path: '/:slug(((?!.*\\.(js|json|map|php|ts|vue|xml|xslt?|ya?ml)$).*))',
  validate(route) {
    return (
      !route.path.match(/\/https?:/) &&
      !route.path.match(/^\/api/) &&
      !route.path.match(/^\/?__nitro/) &&
      !route.path.match(/^\/?_nuxt/) &&
      !route.path.match(/.*\.(js|json|map|php|ts|vue|xml|xslt?|ya?ml)$/)
    )
  },
})

/**
 * This is a vercel specific function that allows us to define the og:image
 * Depends on a Template component; like ~/components/OgImage/Template.vue
 * @see https://vercel.com/docs/frameworks/nuxt#open-graph-images
 */
/**
defineOgImageComponent('Template', {
  title: 'Is this thing on?'
})
*/
</script>
